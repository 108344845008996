<template>
<section class="richText-wrapper thumb-bar">
    <div class="richText-content" v-if="richArray.length>0">
        <div class="richText-item" v-for="(item,i) in richArray" :key="i">
            <p class="richText-text" v-if="item.text">{{item.text}}</p>
            <p class="richText-img" v-if="item.img">
                <el-image class="img" :src="item.img.img" alt="图片">
                    <div slot="placeholder" class="image-slot">
                        加载中<span class="dot">...</span>
                    </div>
                </el-image>
                
            </p>
            <div class="edit-box flex align-center">
                <span class="icon mtui-icon-up" @click="operationHandler($event,'up',i)"></span>
                <span class="icon mtui-icon-down" @click="operationHandler($event,'down',i)"></span>
                <span v-if="item.text" class="icon mtui-icon-xuigaiziliao" @click="operationHandler($event,'editText',i)"></span>
                <label class="icon" v-if="item.img">
                    <span class=" mtui-icon-xuigaiziliao"></span>
                    <input type="file" accept="image/*" ref="addImg" style="display:none;" @change="operationHandler($event,'editImg',i)">
                </label>
                <span class="icon mtui-icon-delete" @click="operationHandler($event,'del',i)"></span>
            </div>
        </div>
    </div>
    <!-- tool -->
    <div class="richText-tools flex " v-show="!addFlag">
        <label class="tool-item flex flex-column align-center flex-center">
            <span class="icon mtui-icon-tup"></span>
            <span class="name">添加图片</span>
            <input type="file" accept="image/*" ref="addImg" style="display:none;" @change="addImgHandler">
        </label>
        <p class="tool-item flex flex-column align-center flex-center" @click="addTextHandler">
            <span class="icon mtui-icon-wenzi"></span>
            <span class="name">添加文字</span>
        </p>
    </div>

    <div class="richText-add" v-show="addFlag" @click="addFlag=!addFlag">
        <span class="el-icon el-icon-plus"></span>
    </div>

    <!-- 弹层输入文字 -->
    <el-dialog title="输入文字" :visible.sync="dialogTextVisible"> 
        <el-input style="width: 300px;" ref="textInput" v-model="textValue" placeholder="请输入文字"></el-input>
        <div slot="footer" class="dialog-footer">
            <el-button @click="dialogTextVisible = false; textValue='';">取 消</el-button>
            <el-button type="primary" @click="changeTextHandler">确 定</el-button>
        </div>
    </el-dialog>
</section>
</template>

<script>
export default {
    props:{value:Array},
    data(){
        return {
            richText:'', // text,img
            richArray:[], // 保存富文本的数据
            addFlag: true, // 是否显示添加按钮
            dialogTextVisible: false,
            textValue: '',
            textOperation:-1, // add,edit 文本操作的方向
            autofocus: false,
        }
    },
    watch:{
        value(val){
            this.richArray = val;
        },
        richArray(val){
            this.$emit('input',val);
        }
    },
    methods:{
        async addImgHandler(e){
            //  添加图片
            const imgs = await this.uploadHandler(e);
            console.log(imgs);
            imgs.forEach(img=>{
                this.richArray.push({img:img});
            });
            this.addFlag = !this.addFlag;
        },
        async uploadHandler(e){
            //  上传图片
            console.log(e);
            let files = e.target.files;
            //  todo 上传图片...
            //  测试
            const _URL = window.URL || window.webkitURL;
            let res = [];
            const localPath = _URL.createObjectURL(files[0]);
            const rs = await this.$https.uploadImage('test',files[0]);
            res.push({
                img: localPath,
                fileid: rs
            });
            return res;
        },
        addTextHandler(){
            //  添加文字
            this.dialogTextVisible = true;
            this.$nextTick(()=>{
                this.$refs['textInput'].focus();
            })
            this.textValue = '';
        },
        changeTextHandler(){
            //  改变文字
            if(this.textOperation==-1){
                //  add
                this.richArray.push({text: this.textValue});
            }else{
                //  edit
                this.richArray[this.textOperation].text = this.textValue;
            }
            this.dialogTextVisible = false;
            this.addFlag =!this.addFlag;
        },
        editTextHandler(row){
            //  修改文字
            this.textOperation = row;
            this.textValue = this.richArray[row].text;
            this.dialogTextVisible = true;
        },
        operationHandler(event,dir,row ){
            switch (dir) {
                case 'up':
                    this.upHandler(row);
                    break;
                case 'down':
                    this.downHandler(row);
                    break;
                case 'editImg':
                    console.log(dir,row, event);
                    this.editImgHandler(row,event);
                    break;
                case 'editText':
                    this.editTextHandler(row);
                    break;
                case 'del':
                    this.delHandler(row);
                    break;
            
                default:
                    break;
            }
        },
        upHandler(row){
            console.log(row)
            if(row==0) return;
            let temp = this.richArray[row];
            this.$set(this.richArray,row,this.richArray[row-1]);
            this.$set(this.richArray,row-1,temp);
        },
        downHandler(row){
            console.log(row)
            if(row==this.richArray.length-1) return;
            let temp = this.richArray[row];
            this.$set(this.richArray,row,this.richArray[row+1]);
            this.$set(this.richArray,row+1,temp);
        },
        editImgHandler(row,e){
            const imgs = this.uploadHandler(e);
            console.log(imgs);
            this.richArray[row].img = imgs[0];
            this.addFlag = !this.addFlag;
        },
        delHandler(row){
            console.log(row)
            this.richArray.splice(row,1);
        }
    }
}
</script>

<style lang="scss" scoped>
.richText-wrapper {
    min-width: 500px;
    width: 100%;
    min-height: 500px;
    height: 100%;
    overflow-y: scroll;
    border: 1px solid #ccc;

    .richText-tools {
        width: 100%;
        height: 50px;
        font-size: 12px;
        line-height: 1.5;
        color: #666;
        background: #EEEEEE;

        .tool-item {
            flex: 1;
        }
    } 

    .richText-add {
        height: 50px;
        line-height: 50px;
        color: #ccc;
        font-size: 40px;
        text-align: center;
        border: 1px dashed #ccc;
    }

    .richText-item {
        position: relative;
        font-size: 14px;
        line-height: 2;
        padding: 10px;

        &:hover {
            .edit-box {
                opacity: 1;
                top: 0;
            }
        }

        .img {
            width: 100%;
            height: auto;
        }

        .edit-box {
            position: absolute;
            left: 0;
            top: -999999999px;
            width: 100%;
            height: 100%;
            opacity: 0;
            background: rgba(0,0,0,0.5);
            color: #999;
            transition: opacity 0.3s linear;

            .icon {
                flex: 1;
                font-size: 30px;
                text-align: center;
            }
        }
    }
}
</style>