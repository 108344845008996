<template>
<section class="skuform-wrapper">
    <div class="sku-list">
        <div class="sku-item" v-for="(item,i) in skuInfo" :key="i">
            <div class="sku-item-head">
                <span class="label">规格类别：</span>
                <p class="name clear" v-show="!item.editFlag">
                    <span class="f-l">{{item.key}}</span>
                    <span class="f-r icon mtui-icon-zidingyi" @click="editSkuKeyHandler(i)"></span>
                </p>
                <div class="add-sku-input i-b" v-show="item.editFlag">
                    <input class="input i-b" type="text" v-model="item.editValue"/>
                    <span class="sku-icon el-icon-check" style="color:green;" @click="editSkuKeySureHandler(i)"></span>
                    <span class="sku-icon el-icon-close" style="color:red;" @click="editSkuKeyCancelHandler(i)"></span>
                </div>
                <span class="sku-del" v-if="i!=0" @click="delSkuHandler(i)">删除</span>
            </div>
            <!-- 第一个规格特殊样式 -->
            <div class="sku-content" v-if="i==0">
                <div class="sku-content-item" v-for="(item2,i2) in item.value" :key="i2">
                    <p class="box clear">
                        <span class="f-l name">{{item2}}</span>
                        <span class="f-r el-del el-icon-error" @click="delSkuValueHandler(i,i2)"></span>
                    </p>
                    <label class="upload" :for="i2" v-if="!skuThumb[i2]">
                        <input :id="i2" type="file" accept="image/*" style="display:none;" @change="uploadThumbHandler($event,i2)">
                        <span >上传图片</span>
                    </label>
                    <template v-else>
                        <img :src="skuThumb[i2]&&skuThumb[i2].thumb" alt="" class="thumb">
                        <span class="del" @click="delThumbHandler(i2)">删除图片</span>
                    </template>
                </div>
                <div class="sku-content-add">
                    <input type="text" class="input" placeholder="请输入规格" v-model="item.input"/>
                    <span class="add" @click="addSkuValueHandler(i)">添加规格值</span>
                </div>
            </div>
            <!-- 其余规格通用样式 -->
            <div class="sku-content" v-else>
                <p class="sku-item-common" v-for="(item2,i2) in item.value" :key="i2">
                    <span>{{item2}}</span>
                    <span class="del el-icon-error" @click="delSkuValueHandler(i,i2)"></span>
                </p>
                <div class="sku-add-common">
                    <input type="text" class="input" placeholder="请输入规格" v-model="item.input"/>
                    <span class="add"  @click="addSkuValueHandler(i)">添加规格值</span>
                </div>
            </div>
        </div>
    </div>
    <div class="add-sku" v-show="skuInfo.length<3">
        <div class="add-sku-input i-b" v-show="addFlag">
            <input class="input i-b" type="text" v-model="addSku"/>
            <span class="sku-icon el-icon-check" style="color:green;" @click="addSureHandler"></span>
            <span class="sku-icon el-icon-close" style="color:red;"   @click="addCancelHandler"></span>
        </div>
        <el-button class="add-sku-btn" v-show="!addFlag" @click="addFlag=true;">
            <span class="el-icon el-icon-plus"></span>
            <span>添加规格</span>
        </el-button>
        <span class="sku-tip">最多支持添加三组规格</span>
    </div>
</section>
</template>

<script>
import utils from '@/utils/utils.js';
export default {
    props:{
        skuinfo:{
            type:Array,
            default:()=>{return []}
        },

    },
    data(){
        return {
            skuInfo:[],
            addFlag: false, // 是否
            addSku:'', // 规格input值
            skuThumb:[], // 保存sku的图片
        }
    },
    watch:{
        skuinfo(val){
            this.skuInfo = val;
        },
        skuInfo(val){
            // this.skuInfoKeys = Object.keys(val);
            this.$emit('skuInfoChange',val);
        },
    },
    methods:{  
    
        // 取消添加规格
        addCancelHandler(){
            this.addSku = '';
            this.addFlag = false;
        },
        //  确认添加规格
        addSureHandler(){
            this.skuInfo.push({
                key:this.addSku,
                value:[]
            });
            console.log(this.skuInfo);
            this.addSku = '';
            this.addFlag = false;
        },
        // 添加规格值
        addSkuValueHandler(i){
            let val = this.skuInfo[i].input;
            if(!val){
                this.$message.error('请填写规格值');
                return;
            }
            if(i==0){
                this.skuInfo[i].thumb = [];
            }
            this.skuInfo[i].value.push(val);
            this.skuInfo[i].input = '';
            this.$forceUpdate();
        },
        delSkuHandler(i){
            this.skuInfo.splice(i,1);
        },
        //  删除某个规格值
        delSkuValueHandler(i,k){
            this.skuInfo[i].value.splice(k,1);
            if(i==1){
                //  第一个规格还有删除图片数据
                this.skuThumb.splice(k,1);
            }
        },
        //  修改sku的key值
        editSkuKeyHandler(i){
            let item = this.skuInfo[i];
            item.editFlag = true;
            item.editValue = item.key;
            this.$set(this.skuInfo,i,item);
        },
        //  确认修改sku的key值
        editSkuKeySureHandler(i){
            let item = this.skuInfo[i];
            item.editFlag = false;
            item.key = item.editValue;
            this.$set(this.skuInfo,i,item);
        },
        //  取消修改sku的key值
        editSkuKeyCancelHandler(i){
            let item = this.skuInfo[i];
            item.editFlag = false;
            // item.key = item.editValue;
            this.$set(this.skuInfo,i,item);
        },
        //  上传缩略图
        async uploadThumbHandler($event,i){ 
            console.log($event,i);
            let file = $event.target.files[0];
            const localPath = utils.Img_URL(file);
            const rs = await this.$https.uploadImage('test',file);
            this.$set(this.skuThumb,i,{
                thumb: localPath,
                fileid: rs
            });
            let thumb = this.skuInfo[0];
            thumb.thumb[i] = rs;
            this.$set(this.skuInfo,0,thumb);
            console.log(this.skuThumb)

        },
        //  删除缩略图
        async delThumbHandler(i){
            await this.$https.delImage([this.skuThumb[i].fileid]);
            this.skuThumb.splice(i,1);
            let thumb = this.skuInfo[0];
            thumb.thumb.splice(i,1)
            this.$set(this.skuInfo,0,thumb);
        },
        setSkuThumb(val){
            this.skuThumb = val;
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/common.scss';
.skuform-wrapper {
    width: 897px;
    display: inline-block;
    border: 1px solid #ccc;

    .sku-item-head {
        padding: 0 15px;
        font-size: 12px;
        border-bottom: 1px solid #ccc;

        .sku-del {
            float: right;

        }

        .label {
            color: #333;
        }

        .name {
            display: inline-block;
            width: 160px;
            height: 30px;
            line-height: 32px;
            padding: 0 10px;
            border: 1px solid #B2B2B2;
            border-radius:2px;
            vertical-align: middle;
            background: #fff;
        }
    }

    .sku-content {
        padding-left: 85px;  
        border-bottom: 1px solid #ccc;

        .upload {
            display: inline-block;
            line-height: 30px;
            padding:0 5px;
            border: 1px solid #ccc;
            border-radius: 2px;
            color: #666;
            cursor: pointer;

        }
    }

    .sku-content-item {
        margin: 10px 0;
        font-size: 12px;
        .box {
            width: 230px;
            height: 30px;
            padding:  0 5px;
            line-height: 32px;
            display: inline-block;
            vertical-align: middle;
            border: 1px solid #B2B2B2;
            border-radius: 2px;                                                                                                                                                                                                                       
        }
        .el-del {
            font-size: 20px;
            color: #ccc;
            margin-top: 6px;
        }

        .upload {
            vertical-align: middle;
            margin-left: 5px;
        }
        .thumb {
            width: 32px;
            height: 32px;
            margin:  0 5px;
            vertical-align: middle;
        }

        .del {
            color: $baseColor;
        }
    }

    .sku-content-add {
        margin: 10px 0;
        font-size: 12px;
        .input {
            width: 240px;
            height: 30px;
            line-height: 32px;
            text-indent: 5px;
            vertical-align: middle;
            border: 1px solid #B2B2B2;
            border-radius: 2px;
        }

        .add {
            color: $baseColor;
            padding-left: 10px;
            cursor: pointer;
        }
    }

    .sku-item-common {
        display: inline-block;
        width: 120px;
        height: 30px;
        line-height: 32px;
        border: 1px solid  #B2B2B2;
        position: relative;
        margin: 10px 10px 10px 0;
        font-size: 12px;
        padding: 0 5px; 

        .del {
            position: absolute;
            font-size: 16px;
            top: 0;
            right: 0;
            transform: translate(50%,-50%);
        }
    }

    .sku-add-common {
        display: inline-block;

        .input {
            width: 120px;
            height: 30px;
            line-height: 32px;
            border: 1px solid  #B2B2B2;
            position: relative;
            margin: 10px 10px 10px 0;
            font-size: 12px;
            padding: 0 5px; 
        }

        .add {
            font-size: 12px;
            color: $baseColor;
        }
    }

    .sku-item-head,.add-sku {
        height: 40px;
        line-height: 40px;
        background: #F8F8F8;
        color: #999999;

        .input {
            height: 30px;
            border: 1px solid #ccc;
            border-radius: 2px;
            margin: 0 5px;
            padding: 0 5px;
        }

        .sku-icon {
            font-size: 20px;
            padding: 5px;
            vertical-align: middle;
            cursor: pointer;
        }

        .add-sku-btn {
            margin-left: 5px;
            color: $baseColor;
            border-color: $baseColor;
        }

        .sku-tip {
            font-size: 12px;
            padding-left: 5px;
        }
    }
    
}
</style>