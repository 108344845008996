<template>
<section class="sku-list-wrapper" v-show="skuInfo.length>0">
    <div class="head flex space-between align-center">
        <span>在标题栏中输入和批量填充</span>
        <el-button class="btn" type="primary" @click="batchHandler">批量填充</el-button>
    </div>
    <div class="table" v-show="skuInfo.length>0">
        <div class="thead flex">
            <p class="th" v-for="(item,i) in skuInfo" :key="'th'+i">{{item.key}}</p>
            <p class="th" v-for="(item,i) in skuFormListKey" :key="'th1'+i">
                <el-input class="input" type="number" v-model="item.value" :placeholder="item.placeholder"></el-input>
            </p>
            
        </div>
        <div class="tr flex" >
            <div class="td flex flex-column  flex-center" v-for="(item,i) in skuInfo" :key="'td'+i">
                <template v-if="i==0">
                    <p v-for="(v,k) in item.value" :key="'td1'+k">{{v}}</p>
                </template>
                <template v-else-if="i==1"> 
 
                    <div class="flex flex-item flex-column  flex-center" v-for="(loop,m) in new Array(skuInfo[i-1].value.length*i)" :key="m">
                        
                        <p v-for="(v,k) in item.value" :key="'td1'+k">{{v}}</p>
                    </div>
                </template>
                <template v-else> 
 
                    <div class="flex flex-item flex-column  flex-center" v-for="(loop,m) in new Array(skuInfo[i-1].value.length*skuInfo[i-2].value.length)" :key="m">
                        
                        <p v-for="(v,k) in item.value" :key="'td1'+k">{{v}}</p>
                    </div>
                </template>
            </div> 
            <div class="td flex flex-column flex-center" v-for="(item,i) in skuFormListKey" :key="'td2'+i">
                <p v-for="(sku,k) in skuFormList" :key="'td3'+k">
                    <input type="text" class="input" v-model="sku[item.key]"/>
                </p>
                
            </div>
        </div>
    </div>
</section>
</template>

<script>
export default {
    props:{
        skuinfo:{
            type: Array,
            default:()=>{return []}
        },
        skulist:{
            type: Array,
            default:()=>{return []}
        },
    },
    data(){
        return {
            skuInfo:[],
            skuList:[],
            skuThumb:[],
            skuFormList:[],
            skuFormListKey:[
                {key:'oriPrice',value:'',placeholder:'原价（元）'},
                {key:'price',value:'',placeholder:'销售价（元）'},
                {key:'quantity',value:'',placeholder:'库存（件）'},
                {key:'productCode',value:'',placeholder:'商品编号'},
            ]
        }
    },
    watch:{
        skuinfo:{
            handler(val){
                //  写个笨方法, 以后有空优化
                if(val.length==0) return;  
                this.skuInfo = val;
                let skuFormList = [];
                if(this.skuInfo.length==1){
                    let len = this.skuInfo[0].value.length;
                    for(let i=0;i<len;i++){
                        let skuFormItem = this.skuFormList[i] || {};
                        skuFormList.push({
                            iconUrl:this.skuInfo[0].thumb[i] || '',
                            oriPrice:skuFormItem.oriPrice || '',
                            price:skuFormItem.price ||'',
                            quantity: skuFormItem.quantity ||'',
                            productCode:skuFormItem.productCode ||'',
                            sku_id:this.skuInfo[0].key+':'+this.skuInfo[0].value[i]
                        })
                    }
                }else if(this.skuInfo.length==2){
                    let len = this.skuInfo[0].value.length;
                    let len2 = this.skuInfo[1].value.length
                    for(let i=0;i<len;i++){
                        let sku_id = this.skuInfo[0].key+':'+this.skuInfo[0].value[i];
                        for(let k=0;k<len2;k++){
                            let skuFormItem = this.skuFormList.filter((item)=>item.sku_id==sku_id+';'+this.skuInfo[1].key+':'+this.skuInfo[1].value[k]);
                            skuFormItem = skuFormItem[0] || {};
                            skuFormList.push({
                                iconUrl:this.skuInfo[0].thumb[i] || '',
                                oriPrice:skuFormItem.oriPrice || '',
                                price:skuFormItem.price ||'',
                                quantity: skuFormItem.quantity ||'',
                                productCode:skuFormItem.productCode ||'',
                                sku_id:sku_id+';'+this.skuInfo[1].key+':'+this.skuInfo[1].value[k]
                            })
                        }
                    }
                }else if(this.skuInfo.length==3){
                    let len = this.skuInfo[0].value.length;
                    let len2 = this.skuInfo[1].value.length;
                    let len3 = this.skuInfo[2].value.length;
                    for(let i=0;i<len;i++){
                        let sku_id = this.skuInfo[0].key+':'+this.skuInfo[0].value[i];
                        for(let k=0;k<len2;k++){
                            let sku_id2 = this.skuInfo[1].key+':'+this.skuInfo[1].value[k];
                            for(let m=0;m<len3;m++){
                                let skuFormItem = this.skuFormList.filter((item)=>item.sku_id==sku_id+';'+sku_id2+';'+this.skuInfo[2].key+':'+this.skuInfo[2].value[m]);
                                skuFormItem = skuFormItem[0] || {};
                                skuFormList.push({
                                    iconUrl:this.skuInfo[0].thumb[i] || '',
                                    oriPrice:skuFormItem.oriPrice || '',
                                    price:skuFormItem.price ||'',
                                    quantity: skuFormItem.quantity ||'',
                                    productCode:skuFormItem.productCode ||'',
                                    sku_id:sku_id+';'+sku_id2+';'+this.skuInfo[2].key+':'+this.skuInfo[2].value[m]
                                })
                            }
                        }
                    }
                }
               
                this.skuFormList = skuFormList;
            },
            deep: true
        },
        skuFormList(val){
            this.$emit('update:skulist',val);
        },
    },
    methods:{
        batchHandler(){
            //  批量
            this.skuFormList.forEach(item=>{
                if(this.skuFormListKey[0].value){
                    item.oriPrice = this.skuFormListKey[0].value;
                }
                if(this.skuFormListKey[1].value){
                    item.price = this.skuFormListKey[1].value;
                }
                if(this.skuFormListKey[2].value){
                    item.quantity = this.skuFormListKey[2].value;
                }
                if(this.skuFormListKey[3].value){
                    item.productCode = this.skuFormListKey[3].value;
                }
                
            })
        },
        setSkuList(val){
            this.skuFormList = val;
        }
    }
}
</script>

<style lang="scss" scoped>
.sku-list-wrapper {
    width: 1050px;

    .head {
        line-height: 45px;
        font-size: 12px;
        color: #999;

        .btn {
            line-height: 1;
            height: 32px;
        }
    }

    .table {
        width: 100%;
        border: 1px solid #CBCBCF;
    }

    .thead {
        height: 45px;
        line-height: 45px;
        text-align: center;
        background: rgba(248,248,248,1);
        .th {
            flex:1;
            color: #333;
        }

        .th+.th {
            border-left: 1px solid #CBCBCF;
        }
        .input {
            width: 120px;
        }
    }

    .tr {
       
        border-top: 1px solid #CBCBCF;

        .td {
            flex: 1;

            p {
                flex: 1;
                text-align: center;
                justify-content: center;
            }

            p+p, div+div {
                border-top: 1px solid #CBCBCF;
            }

            .input {
                width: 120px;
            }
        }

        .td+.td {
            border-left: 1px solid #CBCBCF;
        }
    }
}
</style>